<template>
  <div class="topic">
    <search-bar />

    <div class="title">主题推荐</div>
    <div
      class="topic-box"
      v-loading="loading"
      element-loading-background="#121330"
    >
      <div
        class="topic-list-item"
        v-for="(item, index) in topicList"
        @click="goTopicDetail(item)"
        :key="index"
      >
        <div class="topic-list-item-left">
          <div class="topic-list-item-left-wrapper">
            <img
              :src="getImg(item.images[0], '140', '180')"
              alt=""
              class="topic-list-item-left-wrapper-background"
            />
          </div>
          <img :src="getImg(item.images[0], '80', '114')" alt="" class="topic-list-item-left-p1" />
          <img :src="getImg(item.images[1], '80', '114')" alt="" class="topic-list-item-left-p2" />
          <img :src="getImg(item.images[2], '80', '114')" alt="" class="topic-list-item-left-p3" />
        </div>
        <div class="topic-list-item-right">
          <div class="topic-list-item-right-wrapper">
            <div class="topic-list-item-right-title">
              {{ item.topicName ? item.topicName : '' }}
            </div>
            <div class="topic-list-item-right-number">
              {{ item.topicNumber }}部
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <page-select
      v-show="moiveList.length"
      :pagination="pagination"
      @pageChange="pageChange"
    ></page-select> -->
  </div>
</template>

<script>
import searchBar from "./search-bar";
import pageSelect from "./page-select";
export default {
  name: "topic",
  data() {
    return {
      backgroundImage: require("@assets/index.png"),
      avatarImage: require("@assets/avatar.png"),
      wantIcon: require("@assets/explore/want.png"),
      timeIcon: require("@assets/aside/aside7.png"),
      emptyIcon: require("@assets/explore/empty.png"),
      selectIcon: require("@assets/recommond/select.png"),
      selectIcon2: require("@assets/recommond/select2.png"),
      noworkIcon: require("@assets/detail/empty.png"),
      searchWidth: 0,
      searchPlaceHolder: "复仇者联盟",
      searchVal: "",
      user: {
        name: "胖虎不是铁憨憨",
      },

      topicList: [],
      loading: true,
      pagination: {
        currentPage: 1,
        pageSize: 0,
        numFound: 0,
        totalPage: 0,
      },
    };
  },
  methods: {
    async init() {
      this.loading = true;
      const result = await this.api.getTopic();

      this.topicList = result.data.list
       this.loading = false;
      setTimeout(() => {

        // for (let i = 0; i < 10; i++) {
        //   this.topicList.push({
        //     topicId: 1,
        //     topicName:
        //       "#宫崎骏赋予它灵魂，并深深打动每一个人每一个人并深深打动每一个人每一个人并深深打动每一个人每一个人",
        //     topicNumber: 20,
        //     images: [
        //       require("@assets/topic/1.png"),
        //       require("@assets/topic/2.png"),
        //       require("@assets/topic/3.png"),
        //     ],

        //   });
        // }
      }, 500);
    },
    goTopicDetail(item) {
      // this.$router.push({
      //   name: 'topic-detail',
      //   query: {
      //     id: item.topicId
      //   }
      // })
      if (location.href.includes('/topic-detail')) {
        // location.href = '/topic-detail?id=' + item.topicId
        this.$router.push('/topic-detail?id=' + item.topicId)
      } else {
        // location.href = '/topic-detail?id=' + item.topicId
        this.$router.push('/topic-detail?id=' + item.topicId)
        // this.$router.push('/detail?id=' + videoId);
      }
    }
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  components: {
    searchBar,
    pageSelect,
  },
};
</script>

<style lang="scss">
.topic {
  background: #121330;
  width: calc(100% - 100px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding: 0 50px;
  .title {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 42px;
    color: #ffffff;
    margin-top: 80px;
    user-select: none;
  }
  .topic-box {
    margin-top: 88px;
    min-height: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .topic-list-item {
      width: 30%;
      margin-right: 3%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: #1a1936;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
      opacity: 1;
      border-radius: 4px;
      margin-bottom: 30px;
      cursor: pointer;
      .topic-list-item-left {
        width: 30%;
        position: relative;
        .topic-list-item-left-wrapper {
          width: 100%;
          height: 100%;
          overflow: hidden;
          .topic-list-item-left-wrapper-background {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            filter: blur(10px);
          }
        }

        .topic-list-item-left-p1 {
          width: 60%;
          position: absolute;
          z-index: 10;
          width: 60%;
          top: 5%;
          right: 15%;

border-radius: 4px;
        }
        .topic-list-item-left-p2 {
          width: 40%;
          position: absolute;
          z-index: 9;
          width: 60%;
          top: 15%;
          right: 0;

border-radius: 4px;
        }
        .topic-list-item-left-p3 {
          width: 35%;
          position: absolute;
          z-index: 8;
          width: 60%;
          top: 25%;
          right: -15%;

border-radius: 4px;
        }
      }
      .topic-list-item-right {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        .topic-list-item-right-wrapper {
          margin-left: 20%;
          width:80%;
          .topic-list-item-right-title {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 28px;
            color: #ffffff;
            width: 75%;
            margin-right:0;
          }
          .topic-list-item-right-number {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #9c9da3;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1680px) {

  .topic {
    .title {
      font-size: 24px;
    }
  }
}
</style>
